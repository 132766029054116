import React from "react";
import '../styles/global.css'
import { Navbar, Nav,Container, } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Headers(){
    return(
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
  <Container fluid>
    <Navbar.Brand href="/" className="text-4xl" style={{fontFamily: "Amatic SC", fontSize: 50,}}>Ferdinand</Navbar.Brand>
    <Navbar.Toggle aria-controls="navbarScroll" />
    <Navbar.Collapse id="navbarScroll">
      <Nav
        className="me-auto my-2 my-lg-0"
        style={{ maxHeight: '100px' }}
        navbarScroll
      >
        <Nav.Link href="/">Home</Nav.Link>
        <Nav.Link href="/projects">Projects</Nav.Link>
        <Nav.Link href="/awards" >
          Awards
        </Nav.Link>
        <Nav.Link href="/cv">
            CV
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
    )
}