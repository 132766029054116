import React from "react";
import { Helmet } from 'react-helmet'

export default function Helmetz(){
    return(
        <div>
            <Helmet>
                <title>Ferdinand</title>
                <link rel="icon" href="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/2048px-User_icon_2.svg.png" />
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-7T7QMS3T1E"></script>
                <script
                dangerouslySetInnerHTML={{
                    __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-7T7QMS3T1E');
                    `,
                }}
                />
            </Helmet>
        </div>
    )
}